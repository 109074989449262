import React from "react";

const Blob = () => {
  return (
    <>
      <svg
        height={"200"}
        width="200"
        className="blob"
        xmlns="http://www.w3.org/2000/svg"
        style={{ top: "10px", right: "10px" }}
      >
        <path
          className="blobpath"
          fill="#8BBAFF"
          transform="translate(100 100)"
        >
          <animate
            attributeName="d"
            repeatCount={"indefinite"}
            dur="10000ms"
            values="M49.1,-16.1C58.5,13,57.5,45.4,37.9,62.1C18.2,78.7,-20.1,79.6,-42.3,62.5C-64.5,45.3,-70.5,10.2,-60.8,-19.4C-51.1,-48.9,-25.5,-73,-2.8,-72.1C19.8,-71.2,39.7,-45.2,49.1,-16.1Z;
            M48.5,-22.3C53.7,0.1,42.4,21.4,22.9,36.8C3.5,52.1,-24,61.6,-43,50C-62,38.4,-72.5,5.7,-63.9,-21.4C-55.3,-48.5,-27.6,-70.1,-3,-69.1C21.7,-68.2,43.4,-44.7,48.5,-22.3Z;
            M53.7,-22.7C59.8,1.3,48.2,25.8,27.9,41.2C7.7,56.5,-21.2,62.7,-37.6,51C-53.9,39.3,-57.8,9.6,-49.4,-17.5C-41.1,-44.7,-20.5,-69.3,1.6,-69.9C23.8,-70.4,47.6,-46.8,53.7,-22.7Z;
            M50.9,-14.7C59.7,10.7,56.4,41.8,36.7,57.9C17,73.9,-19,74.8,-41.5,58.1C-64,41.5,-73.1,7.4,-63.9,-18.4C-54.8,-44.3,-27.4,-61.8,-3.2,-60.8C21,-59.7,42,-40.1,50.9,-14.7Z;
            M49.1,-16.1C58.5,13,57.5,45.4,37.9,62.1C18.2,78.7,-20.1,79.6,-42.3,62.5C-64.5,45.3,-70.5,10.2,-60.8,-19.4C-51.1,-48.9,-25.5,-73,-2.8,-72.1C19.8,-71.2,39.7,-45.2,49.1,-16.1Z;"
          ></animate>
        </path>
      </svg>
      <svg
        height={"200"}
        width="200"
        className="blob"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          top: Math.floor(Math.random() * 601) + 400 + "px",
          left: "500px",
        }}
      >
        <path
          className="blobpath"
          fill="#BBF7FF"
          transform="translate(100 100)"
        >
          <animate
            attributeName="d"
            repeatCount={"indefinite"}
            dur="10000ms"
            values="M49.1,-16.1C58.5,13,57.5,45.4,37.9,62.1C18.2,78.7,-20.1,79.6,-42.3,62.5C-64.5,45.3,-70.5,10.2,-60.8,-19.4C-51.1,-48.9,-25.5,-73,-2.8,-72.1C19.8,-71.2,39.7,-45.2,49.1,-16.1Z;
            M48.5,-22.3C53.7,0.1,42.4,21.4,22.9,36.8C3.5,52.1,-24,61.6,-43,50C-62,38.4,-72.5,5.7,-63.9,-21.4C-55.3,-48.5,-27.6,-70.1,-3,-69.1C21.7,-68.2,43.4,-44.7,48.5,-22.3Z;
            M53.7,-22.7C59.8,1.3,48.2,25.8,27.9,41.2C7.7,56.5,-21.2,62.7,-37.6,51C-53.9,39.3,-57.8,9.6,-49.4,-17.5C-41.1,-44.7,-20.5,-69.3,1.6,-69.9C23.8,-70.4,47.6,-46.8,53.7,-22.7Z;
            M50.9,-14.7C59.7,10.7,56.4,41.8,36.7,57.9C17,73.9,-19,74.8,-41.5,58.1C-64,41.5,-73.1,7.4,-63.9,-18.4C-54.8,-44.3,-27.4,-61.8,-3.2,-60.8C21,-59.7,42,-40.1,50.9,-14.7Z;
            M49.1,-16.1C58.5,13,57.5,45.4,37.9,62.1C18.2,78.7,-20.1,79.6,-42.3,62.5C-64.5,45.3,-70.5,10.2,-60.8,-19.4C-51.1,-48.9,-25.5,-73,-2.8,-72.1C19.8,-71.2,39.7,-45.2,49.1,-16.1Z;"
          ></animate>
        </path>
      </svg>
      <svg
        height={"200"}
        width="200"
        className="blob"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          top: Math.floor(Math.random() * 601) + 400 + "px",
          left: "50px",
        }}
      >
        <path
          className="blobpath"
          fill="#BBF7FF"
          transform="translate(100 100)"
        >
          <animate
            attributeName="d"
            repeatCount={"indefinite"}
            dur="10000ms"
            values="M49.1,-16.1C58.5,13,57.5,45.4,37.9,62.1C18.2,78.7,-20.1,79.6,-42.3,62.5C-64.5,45.3,-70.5,10.2,-60.8,-19.4C-51.1,-48.9,-25.5,-73,-2.8,-72.1C19.8,-71.2,39.7,-45.2,49.1,-16.1Z;
            M50.9,-14.7C59.7,10.7,56.4,41.8,36.7,57.9C17,73.9,-19,74.8,-41.5,58.1C-64,41.5,-73.1,7.4,-63.9,-18.4C-54.8,-44.3,-27.4,-61.8,-3.2,-60.8C21,-59.7,42,-40.1,50.9,-14.7Z;
            M48.5,-22.3C53.7,0.1,42.4,21.4,22.9,36.8C3.5,52.1,-24,61.6,-43,50C-62,38.4,-72.5,5.7,-63.9,-21.4C-55.3,-48.5,-27.6,-70.1,-3,-69.1C21.7,-68.2,43.4,-44.7,48.5,-22.3Z;
            M53.7,-22.7C59.8,1.3,48.2,25.8,27.9,41.2C7.7,56.5,-21.2,62.7,-37.6,51C-53.9,39.3,-57.8,9.6,-49.4,-17.5C-41.1,-44.7,-20.5,-69.3,1.6,-69.9C23.8,-70.4,47.6,-46.8,53.7,-22.7Z;
            M49.1,-16.1C58.5,13,57.5,45.4,37.9,62.1C18.2,78.7,-20.1,79.6,-42.3,62.5C-64.5,45.3,-70.5,10.2,-60.8,-19.4C-51.1,-48.9,-25.5,-73,-2.8,-72.1C19.8,-71.2,39.7,-45.2,49.1,-16.1Z;"
          ></animate>
        </path>
      </svg>
    </>
  );
};

export default Blob;
